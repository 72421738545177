// media query mixins
$until-tablet: 767px;
$tablet: 768px;
$until-desktop: 1023px;
$desktop: 1024px;
$desktop-wide: 1440px;

@mixin untilTablet {
  @media only screen and (max-width: $until-tablet) {
    @content;
  }
}

@mixin fromTablet {
  @media only screen and (min-width: $tablet) {
    @content;
  }
}

@mixin untilDesktop {
  @media only screen and (max-width: $until-desktop) {
    @content;
  }
}

@mixin fromDesktop {
  @media only screen and (min-width: $desktop) {
    @content;
  }
}

@mixin fromDesktopWide {
  @media only screen and (min-width: $desktop-wide) {
    @content;
  }
}

// colors
$black: #000;
$white: #fff;
$grey: #e4e4e4;
$grey-disabled: #f4f4f4;
$grey-disabled-dark: #848484;
$grey-disabled-light: $grey;
$grey-light: #f0f0f0;
$green: #00c853;
$green-darker: #00af48;
$blue: #42a5f5;
$subTitle: #00273b;

// DGS-2373-New-green-shade-AB-test
$test-green: #00ad48;
$test-green-dark: #00943d;
$test-green-light: #e5f9ee;

// Seasons (new colours)
$season-eco: #f0f5fa;
$season-low: #e3f2fe;
$season-mid: #c6e4fc;
$season-holiday: #8ec9f9;
$season-reg: #1d93fe;
$season-high: #0a6ab9;
$season-default: #8ec9f9;
$neutral-50: #fafafb;
$neutral-100: #eaecee;
$neutral-200: #dbdee1;
$neutral-300: #ccd0d4;
$neutral-600: #6e7983;
$neutral-700: #495157;
$neutral-800: #25282c;
$primary-800: #06406f;
$disney-gradient-color1: #3d1f98;
$disney-gradient-color2: #933cbc;
// error palette
$color-pink-100: #ffe2e2;
$color-pink-400: #ff6c6c;
$gold: #ffe197;
$season-default-new: $season-low;

$ticket-range: $green;
$ticket-range-start: $green;
$ticket-range-end: $green;

$disney-gradient: linear-gradient(
  92deg,
  $disney-gradient-color1 0.44%,
  $disney-gradient-color2 100%
);

@mixin containerTop {
  padding: 1rem;
  transition: 0.3s;
  position: relative;
  background-color: $white;
  border: 1px solid $grey;
  border-width: 1px 0 0 0;
  border-bottom: none;
  border-radius: 16px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  @include fromDesktop {
    padding: 1.6rem;
  }

  :global(.HOT-637-DLP-Park-Ticket-step-v3) & {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 0;
    @include fromDesktop {
      padding: 0;
    }
  }
}

@mixin topTip {
  :global(.HOT-637-DLP-Park-Ticket-step-v3) & {
    border: solid 1px $neutral-300;
    margin: 0;
    padding: 1.2rem;
    border-radius: 8px;
    position: relative;
    border-left: solid 8px $disney-gradient-color1;
    padding-left: 4rem;
    font-size: 1.6rem;
    color: $neutral-600;
    @include fromDesktop {
      padding-left: 5rem;
    }

    &:before {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      top: 1.2rem;
      left: 1rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 18px;
      @include fromDesktop {
        left: 1.6rem;
      }
    }
  }
}

@mixin container {
  @include containerTop;
  border-top: 1px dashed $grey;
}

@mixin contentContainer {
  :global(.HOT-637-DLP-Park-Ticket-step-v3) & {
    border: solid 1px $neutral-200;
    padding: 1.6rem;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    @include fromDesktop {
      padding: 2.4rem;
    }
  }
}

@mixin noContainer {
  padding: 0;
  border: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;

  &:before,
  &:after {
    display: none;
  }
}

@mixin containerInside {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin h2Style {
  margin: 0 0 1rem 0;
  color: #495157;
  font-size: 2.1rem;
  font-weight: 700;
  :global(.HOT-637-DLP-Park-Ticket-step-v3) & {
    font-size: 2.4rem;
    margin: 0;
    &::before {
      position: absolute;
      left: 0;
    }
  }
}

@mixin subHeading {
  :global(.HOT-637-DLP-Park-Ticket-step-v3) & {
    font-size: 1.6rem;
    color: $neutral-600;
    padding: 0;
    margin: 0;
  }
}

%titleHolder {
  display: block;
  :global(.HOT-637-DLP-Park-Ticket-step-v3) & {
    margin: 0 0 1.6rem 0;
    padding: 0 0 0 2.4rem;
    position: relative;
  }
}

%hotelStay {
  background-color: transparent;
}

%subTitle {
  font-size: 1.6rem;
  font-weight: 700;
  color: $subTitle;
  strong {
    font-weight: 400;
  }
  @include fromTablet {
    :global(.HOT-637-DLP-Park-Ticket-step-v3) & {
      font-size: 1.8rem;
    }
  }
}

%priceBig {
  font-size: 2.4rem;
  line-height: 1;
  font-weight: 700;
}

%priceGreen {
  color: $green;
  font-weight: 700;

  :global(.DGS-2373-New-green-shade-AB-test) & {
    color: $test-green;
  }
}

%price {
  @extend %priceBig;
  @extend %priceGreen;
}

%pStyle {
  font-size: 1.6rem;
  line-height: 1.4;
}

%activeTick {
  border-color: $green !important;

  &::before {
    background: $green;
    content: "";
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    bottom: -25px;
    top: auto;
    right: 50%;
    transform: translateY(0) translateX(50%);
  }

  &::after {
    background: 0 0;
    content: "";
    width: 5px;
    height: 12px;
    border-radius: 0;
    position: absolute;
    border: 5px solid #fff;
    border-left: none;
    border-top: none;
    bottom: -15px;
    top: auto;
    right: 50%;
    transform: translateY(-53%) translateX(50%) rotate(45deg);
  }

  // DGS-2373-New-green-shade-AB-test
  :global(.DGS-2373-New-green-shade-AB-test) & {
    border-color: $test-green !important;

    &::before {
      background: $test-green;
    }
  }
}

%smallText {
  font-size: 1.3rem;
  line-height: 1.2;
}

%textText {
  font-size: 1.4rem;
}
