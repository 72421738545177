@import "./styles/_base.scss";

// make fade in divs relative
#assisted-navigation-ticket {
  & > div > div {
    position: relative;
  }
}

body {
  // calendar overrides
  .CalendarDay {
    position: relative;
    width: calc(100% / 7) !important;
    height: auto !important;
    padding: 0;

    &:active,
    &:hover {
      background-color: transparent;
      border: none;
    }

    .one-day {
      display: block;
      padding: 4px;

      p.dateInfo {
        border: none;
        top: 0;
        left: 0;
        height: auto;
        width: 100%;
        position: relative;
        display: block;
        line-height: 1.4;
        border-radius: 0;
        padding: 0.75rem 0 1rem 0;
        margin: 0;
        border-radius: 4px;
        background-color: $season-default;

        strong {
          display: block;
          font-weight: 400;
          font-size: 1.8rem;
        }
        em {
          display: block;
          font-weight: 400;
          font-style: normal;
          font-size: 1.2rem;
          visibility: hidden;
          min-height: 16px;
        }
      }

      &.hotel-stay {
        @extend %hotelStay;
      }
      &.eco-season {
        p.dateInfo {
          background-color: $season-eco;
          color: $black;
          em {
            visibility: visible;
          }
        }
      }

      &.low-season {
        p.dateInfo {
          background-color: $season-low;
          color: $black;
          em {
            visibility: visible;
          }
        }
      }

      &.mid-season {
        p.dateInfo {
          background-color: $season-mid;
          color: $black;
          em {
            visibility: visible;
          }
        }
      }

      &.holiday {
        p.dateInfo {
          background-color: $season-holiday;
          color: $black;
          em {
            visibility: visible;
          }
        }
      }

      &.reg-season {
        p.dateInfo {
          background-color: $season-reg;
          color: $white;
          em {
            visibility: visible;
          }
        }
      }

      &.high-season {
        p.dateInfo {
          background-color: $season-high;
          color: $white;
          em {
            visibility: visible;
          }
        }
      }

      &.ticket-range {
        p.dateInfo {
          background-color: $ticket-range;
          color: $white;

          em {
            visibility: hidden;
          }

          :global(.DGS-2373-New-green-shade-AB-test) & {
            background-color: $test-green;
          }
        }
      }

      &.ticket-range-start {
        p.dateInfo {
          background-color: $ticket-range-start;
          color: $white;

          em {
            visibility: visible;
          }

          :global(.DGS-2373-New-green-shade-AB-test) & {
            background-color: $test-green;
          }
        }
      }

      &.ticket-range-end {
        p.dateInfo {
          background-color: $ticket-range-end;
          color: $white;

          :global(.DGS-2373-New-green-shade-AB-test) & {
            background-color: $test-green;
          }
        }
      }
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: #8fe1a0;
    border: 1px double #00c852;

    // DGS-2373-New-green-shade-AB-test
    :global(.DGS-2373-New-green-shade-AB-test) & {
      border-color: $test-green;
      background: $test-green-light;
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background-color: transparent;
    border: none;
    color: #484848;
  }

  .CalendarDay__blocked_calendar {
    cursor: not-allowed;
    color: $grey-disabled-light;
    background-color: $white;



    .one-day p.dateInfo {
      background-color: $white;
      color: $grey-disabled-light;
      cursor: not-allowed;
    }

    .one-day p.dateInfo em {
      visibility: hidden;
    }
  }

  .CalendarDay__blocked_calendar:active,
  .CalendarDay__blocked_calendar:hover {
    background-color: transparent;
    color: $grey-disabled-dark;
  }

  .DayPicker {
    table,
    td {
      border: none;
    }
  }

  #assisted-navigation-ticket .DayPicker_transitionContainer {
    height: auto !important;
    min-height: 480px;
  }
  .DayPicker,
  .DayPicker > div:first-child,
  .DayPicker > div:first-child > div:first-child,
  .DayPicker_weekHeaders,
  .DayPicker_weekHeader,
  .DayPicker_weekHeader_ul,
  .DayPicker_focusRegion,
  .DayPickerNavigation,
  .CalendarMonthGrid__horizontal,
  .CalendarMonthGrid_month__horizontal,
  .DayPicker_transitionContainer {
    width: 100% !important;
  }
  .DayPicker_weekHeaders__horizontal {
    margin: 0;
  }
  .CalendarMonthGrid__horizontal {
    left: 0;
  }
  .DayPickerNavigation_button__horizontalDefault {
    top: 0;
    border-radius: 4px;
    background-color: $grey-light;
    width: 55px;
    height: 40px;
    border: none;
    padding: 0;
    line-height: 1;

    @include fromDesktop {
      width: 35px;
      height: 35px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: solid 7px transparent;
      width: 0;
      height: 0;
      border-radius: 4px;
    }
    svg {
      display: none;
    }

    &:hover,
    &:active,
    &:focus {
      border: none;
      background-color: $grey;
    }
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: auto;
    right: 69px;
    &:after {
      border-right-color: $black;
      margin-left: -4px;
    }
    @include fromDesktop {
      right: 49px;
    }
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    right: 4px;
    &:after {
      border-left-color: $black;
      margin-left: 4px;
    }
  }
  .DayPicker_weekHeader {
    top: 50px;
    padding: 0 !important;
  }
  .DayPicker_weekHeader_ul {
    display: flex;
  }
  .DayPicker_weekHeader_li {
    flex-grow: 1;
    small {
      text-transform: uppercase;
      font-weight: 800;
      color: $subTitle;
      width: 100%;
      font-size: 1.2rem;
    }
  }
  .CalendarMonth {
    padding: 0 !important;
  }
  .CalendarMonth_caption {
    @extend %subTitle;
    padding: 0 0 0 4px;
    min-height: 75px;
    text-align: left;
  }
}

.HOT-637-DLP-Park-Ticket-step-v3 {
  #assisted-navigation-ticket {
    .DayPicker_transitionContainer {
      min-height: 430px;
      @include fromDesktop {
        min-height: 480px;
      }
    }
  }
  .CalendarDay {
    .one-day {
      padding: 2px;
      @include fromDesktop {
        padding: 2px;
      }
      p.dateInfo {
        background-color: $season-default-new;
        border: none;
        padding: 1.6rem 0;
        em {
          display: none;
        }
        strong {
          font-size: 1.6rem;
        }
        &:hover {
          background-color: $green;
          color: $white;

          // DGS-2373-New-green-shade-AB-test
          :global(.DGS-2373-New-green-shade-AB-test) & {
            background-color: $test-green;
          }
        }
      }
    }
  }
  .CalendarDay__blocked_calendar {
    .one-day {
      p.dateInfo {
        background-color: $white;
        color: $grey-disabled-dark;
        &:hover {
          background-color: $white;
          color: $grey-disabled-dark;
        }
      }
    }
  }
  .DayPicker_weekHeader_li {
    small {
      text-transform: none;
      font-size: 1.4rem;
      color: $neutral-600;
      font-weight: 700;
    }
  }
  .CalendarMonth_caption {
    strong {
      font-size: 1.6rem;
      font-weight: 700;
      color: $neutral-700;
      display: block;
      text-align: center;
      @include fromDesktop {
        font-size: 2rem;
      }
    }
  }
  .DayPickerNavigation_leftButton__horizontalDefault {
    left: 0;
    right: auto;
    background-color: $neutral-100;
    @include fromDesktop {
      left: 0;
      right: auto;
    }
    &:after {
      border-right-color: $neutral-700;
    }
  }
  .DayPickerNavigation_rightButton__horizontalDefault {
    left: auto;
    right: 0;
    background-color: $neutral-100;
    @include fromDesktop {
      left: auto;
      right: 0;
    }
    &:after {
      border-left-color: $neutral-700;
    }
  }
}

// DGS-2373-New-green-shade-AB-test
.DGS-2373-New-green-shade-AB-test {
  .CalendarDay .one-day {
    &.ticket-range,
    &.ticket-range-start,
    &.ticket-range-end {
      p.dateInfo {
        background-color: $test-green;
      }
    }
  }
}

.HOT-637-DLP-Park-Ticket-step-v3.DGS-2373-New-green-shade-AB-test {
  .CalendarDay .one-day {
    p.dateInfo:hover {
      background-color: $test-green;
    }
  }
}
